:root {
  --newsBarrier: 1.5rem;
  --topBarrier: 2.5rem;
  --topStart: 0rem;
  --cardHeight: 30vh;
  --marketSummaryHeight: 6vh;
  --normalMargin: 0.1vw;
  --borderRadius: 0px;
  --graphTop: calc(var(--marketSummaryHeight));
  --graphHeight: 40vh;
  --graphHeightSmall: 20vh;
  --graphMinWidth: 350px;
  --buySellTop: calc(var(--marketSummaryHeight) + var(--graphHeight));
  --buySellHeight: 17vh;
  --auctionBuySellHeight: 21vh;
  --openTradesTop: calc(
    var(--marketSummaryHeight) + var(--graphHeight) + var(--buySellHeight)
  );
  --openTradesHeight: calc(22vh + 2 * var(--normalMargin));
  --tradesBookHeight: 25vh;
  --exchangeViewHeight: 90vh;
  --pageViewHeight: 780px;
  --rightSideWidth: 26vw;
  --rightSideMargin: 26.1vw;
  --leftSideWidth: 15vw;
  --leftSideMargin: 15.1vw;
  --backgroundColor: rgb(10, 36, 77);
  --headerBackgroundColor: rgb(10, 30, 61, 0.95);
  --exchangeColor: rgba(2, 37, 52, 0.95); /*rgba(4, 21, 34, 0.95);*/
  --logoInnerColor: rgba(2, 37, 52, 0.5); /*rgba(21, 54, 116, 0.5);*/
  --logoBackgroundColor: rgba(2, 37, 52, 0); /*rgba(40, 108, 233, 0);*/
  --toastInnerColor: rgba(49, 79, 110, 0.9); /*rgba(21, 54, 116, 1);*/
  --toastOuterColor: rgba(2, 37, 52, 0.9); /*rgba(40, 108, 233, 0.5);*/
  --toastErrorInnerColor: rgba(147, 79, 149, 0.9); /*rgba(231, 54, 56, 1);*/
  --toastErrorOuterColor: rgba(82, 37, 78, 0.9); /*rgba(150, 6, 9, 0.5);*/
  --exchangeHighliteColor: rgba(40, 108, 233, 0.8);
  --gradientStartColor: rgb(9, 79, 110, 0.15); /*rgba(30, 80, 172, 0.3);*/
  --gradientEndColor: rgba(2, 37, 52, 0); /*rgba(40, 108, 233, 0);*/
  --overlayBannerColor: rgba(44, 44, 44, 0.5);
  --overlayNewsBannerColor: rgba(44, 44, 44, 0.9);
  --windowColor: rgba(9, 79, 110, 0.1); /*rgba(0, 74, 255, 0.1);*/
  --windowBorder: 1px solid rgba(77, 77, 77, 0.5);
  --viewBuySellBackground: rgba(9, 79, 110, 0.1); /*rgba(121, 181, 255, 0.01);*/
  --viewBackground: rgba(2, 37, 52, 0.05); /*rgba(121, 181, 255, 0.05);*/
  --viewBorder: 1px solid rgba(255, 255, 255, 0.008);
  --redColor: rgba(184, 44, 44, 0.95);
  --greenColor: rgba(44, 144, 44, 0.95);
  --blueColor: rgba(150, 199, 213, 0.95);
  --itemHighliteColor: rgb(212, 103, 255);
  --grayColor: rgba(157, 157, 157, 1);
  --textColor: rgb(205, 222, 231); /*rgba(200, 200, 200, 1);*/
  --textHoverColor: rgb(253, 182, 255); /*rgba(200, 200, 200, 1);*/
  --textColorActive: rgba(220, 200, 200, 1);
  --textColorCompleted: rgba(170, 220, 170, 1);
  --textColorDisabled: rgba(100, 100, 100, 1);
  --hoverColor: rgb(150, 199, 213); /*rgb(0, 141, 223);*/
  --marketTextHighlite: rgb(150, 199, 213);
  --centerModalImage: rgba(238, 90, 252, 0.5);

  --locationInnerColor: rgba(99, 79, 110, 0.9);
  --locationOuterColor: rgba(49, 109, 110, 0.9);
  --supplyLocationColor: rgba(210, 61, 255, 0.8);
  --demandLocationColor: rgba(20, 255, 51, 0.8);

  --cardBackground: rgba(61, 61, 200, 0.1);
}

/*
 rgb(228, 245, 255)
 rgb(150, 199, 213)
 rgb(45, 122, 156)
 rgb(9, 79, 110)
 rgb(2, 37, 52)
*/

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App {
  text-align: center;
  line-height: 1.3;
  //background-color: var(--viewBackground);
  //color: rgb(205, 222, 231);
  //color: var(--textColor);
  //font-size: 1.6vmin;
}

.settingsWindow {
  background-color: rgba(54, 54, 54, 1);
  border: 2px solid rgb(77, 77, 77);
  border-radius: var(--borderRadius);
  box-shadow: 15px 15px 15px rgba(20, 20, 20, 0.5);
  color: rgb(204, 204, 204);
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  z-index: 30;
  position: absolute;
  margin: auto;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 480px;
  min-width: 280px;
}

.statusNormalWindow {
  text-align: left;
  color: rgba(229, 229, 229, 0.151);
  left: 10px;
  position: absolute;
  bottom: 3px;
}

.statusErrorWindow {
  text-align: right;
  color: rgba(254, 209, 209, 0.4);
  bottom: calc(var(--topBarrier) * 0.05);
  right: 10px;
  position: absolute;
  font-size: 0.7em;
}

.topLogo {
  background: radial-gradient(
    circle,
    var(--logoInnerColor) 0%,
    var(--logoBackgroundColor) 100%
  );
  padding: 0.5rem 0px;
  height: calc(var(--topBarrier) - 1rem);
  width: var(--leftSideWidth);
  text-shadow: 0 0 0.15em #1da9cc;
}

.topLink {
  color: rgb(229, 229, 229);
}

.dialogLogo {
  background: radial-gradient(
    circle,
    var(--logoInnerColor) 0%,
    var(--logoBackgroundColor) 90%
  );
  border-radius: 0px;
  padding: 0.5rem 0px;
  height: calc(var(--topBarrier) - 1rem);
  width: 100%;
  padding-top: 1em;
}

.bannerLink {
  color: rgb(229, 229, 229);
  padding: 0.7rem 0px;
  font-size: 0.8em;
}

.topBanner {
  background-color: var(--overlayBannerColor);
  background: linear-gradient(
    180deg,
    var(--logoInnerColor) 50%,
    var(--logoBackgroundColor) 100%
  );
  border: none;
  border-radius: 0px;
  color: rgb(204, 204, 204);
  display: grid;
  grid-template-columns: var(--leftSideWidth) auto auto auto auto auto auto auto auto auto;
  z-index: 20;
  position: absolute;
  width: 100%;
  height: var(--topBarrier);
}

.newsBanner {
  background: linear-gradient(
    0deg,
    var(--logoInnerColor) 0%,
    var(--logoBackgroundColor) 100%
  );
  border: none;
  border-radius: 0px;
  color: rgb(204, 204, 204);
  display: grid;
  z-index: 20;
  position: absolute;
  vertical-align: middle;
  text-align: center;
  justify-items: center;
  width: 100%;
  height: var(--newsBarrier);
  top: var(--topBarrier);
}

.footerBanner {
  background-color: var(--overlayBannerColor);
  background: linear-gradient(
    180deg,
    var(--logoBackgroundColor) 0%,
    var(--logoInnerColor) 90%
  );
  border: none;
  border-radius: 0px;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  z-index: 10;
  position: fixed;
  width: 100%;
  bottom: 0px;
  /* height: 7.5em; */
  font-size: 0.8em;
}

.footerText {
  text-overflow: ellipsis;
  min-width: 30px;
  overflow: hidden;
}

.tableText {
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 20px;
}
.tableHeader {
  font-size: 0.7vmax;
  color: var(--grayColor);
}
.tableSummaryText {
  font-size: 1.5vmin;
}
.tableFinancialView {
  overflow: auto;
  text-align: right;
}
table.fixed {
  table-layout: fixed;
}
table.fixed td {
  overflow: hidden;
}

.openTradesText {
  text-overflow: ellipsis;
  min-width: 30px;
  overflow: hidden;
}

.textAsLink {
  text-decoration: underline;
}

.pageViewScrollable {
  position: absolute;
  background: radial-gradient(
    circle,
    var(--gradientStartColor) 0%,
    var(--gradientEndColor) 70%
  );
  top: var(--topBarrier);
  right: 0px;
  left: 0px;
  justify-content: center;
  bottom: 6em;
  overflow: hidden;
}

.pageViewContents {
  height: 95%;
  text-align: left;
  padding: 1em 2em;
  overflow-y: auto;
}

@media only print {
  #printablePageViewContents {
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
  }
}

.pageNormalViewContents {
  height: 95%;
  text-align: center;
  padding: 0em 0.5em;
  overflow-y: auto;
  margin-bottom: 100px;
}

.pageFixedHeaderViewContents {
  height: 87%;
}

.balancesSection {
  justify-content: center;
  display: inline-table;
  width: 90%;
}

.contractsSection {
  border: 1px solid black;
  justify-content: center;
  display: inline-table;
  padding: 5px 5px;
  width: 90%;
  line-height: 1.5;
}

th.contractsSection,
td.contractsSection {
  padding: 15px;
  text-align: left;
}

.shipmentSectionHeader {
  justify-content: right;
  text-align: left;
  display: inline-table;
  margin: 0px 0px 0px 0px;
}

.profileInformationSection {
  justify-content: right;
  text-align: left;
  display: inline-table;
  margin: 0px 0px 30px 0px;
}

.documentInformationSection {
  justify-content: right;
  text-align: left;
  /* display: inline-table; */
  margin: 10px 15% 10% 15%;
}

.profileVerificationSection {
  justify-content: center;
  display: inline-table;
  width: 90%;
}

.viewExchange {
  background: radial-gradient(
    circle,
    var(--gradientStartColor) 0%,
    var(--gradientEndColor) 50%
  );
  position: absolute;
  top: var(--topBarrier);
  width: 100%;
  min-height: var(--exchangeViewHeight);
}

.exchangesBanner {
  background-color: rgba(54, 54, 54, 0.25);
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  color: rgb(204, 204, 204);
  display: grid;
  z-index: 20;
  position: absolute;
  vertical-align: middle;
  text-align: center;
  justify-items: center;
  width: 100%;
  height: var(--marketSummaryHeight);
  left: var(--normalMargin);
  width: calc(var(--leftSideWidth) - var(--normalMargin));
  top: var(--topStart);
}
.marketsInfo {
}

.viewMarkets {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);

  position: absolute;
  text-align: left;
  justify-items: left;
  padding: 0px 0px;
  top: calc(var(--topStart) + var(--marketSummaryHeight));
  left: var(--normalMargin);
  width: calc(var(--leftSideWidth) - var(--normalMargin));
  height: var(--graphHeight);
  z-index: 7;
}
.viewMarketSummary {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  position: absolute;
  height: var(--marketSummaryHeight);
  left: var(--leftSideMargin);
  right: calc(var(--rightSideMargin) + var(--normalMargin));
  display: flex;
  overflow: hidden;
  min-width: var(--graphMinWidth);
  height: var(--marketSummaryHeight);
  top: var(--topStart);
  //animation-name: viewMarketSummaryAnimation;
  //animation-duration: 1s;
}

.dataplaceInfo {
}
.dataplaceViewScrollable {
  position: absolute;
  background: radial-gradient(
    circle,
    var(--gradientStartColor) 0%,
    var(--gradientEndColor) 70%
  );
  top: var(--topBarrier);
  right: 0px;
  left: 0px;
  /* justify-content: center; */
  bottom: 0em;
  overflow: hidden;
}
.dataplaceContents {
  height: 100%;
  text-align: left;
  padding: 1em 2em;
  overflow-y: auto;
}

.viewDataplaceCategories {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  position: absolute;
  text-align: left;
  justify-items: left;
  padding: 0px 0px;
  top: calc(var(--topStart) + var(--marketSummaryHeight) + var(--graphHeight));
  left: var(--normalMargin);
  width: calc(var(--leftSideWidth) - var(--normalMargin));
  height: var(--graphHeight);
  min-width: calc(var(--leftSideWidth));
  z-index: 7;
}
.viewDataObjectsCards {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  align-items: left;
  justify-content: center;
  z-index: 10;
  position: absolute;
  top: var(--graphTop);
  left: calc(var(--leftSideWidth) + var(--normalMargin));
  right: calc(0 * var(--rightSideMargin) + var(--normalMargin));
  bottom: var(--graphTop);
}
.viewDataObjectsCardsFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@keyframes viewMarketSummaryAnimation {
  from {
    height: 0;
  }
  to {
    height: var(--marketSummaryHeight);
  }
}

.viewMarketSummaryTable {
  width: 100%;
}

.exchangesTable {
  overflow: hidden;
  flex-direction: row;
}
.exchangeItem {
  padding: 1px 5px;
}
.exchangeItem:hover {
  color: var(--hoverColor);
}
.marketHeader {
  padding: 2px 0px;
  text-align: center;
}

.marketName {
  width: 60%;
}
.marketPrice {
  width: 40%;
}

.marketItem {
  /* padding: 3px 3px; */
  /* margin: 0px 10px; */
  /* color: var(--textColor); */
  /* font-size: 0.8em; */
}

.marketItem:hover {
  color: var(--hoverColor);
}

.orderHeader {
  padding: 0px 0px;
}

.orderSellItem {
  color: var(--redColor);
  font-size: 0.8em;
}
.orderSellItem:hover {
  color: var(--hoverColor);
}
.orderBuyItem {
  color: var(--greenColor);
  font-size: 0.8em;
}
.orderBuyItem:hover {
  color: var(--hoverColor);
}

#textLeft {
  display: table-cell;
  text-align: left;
}
#textRight {
  display: table-cell;
  text-align: right;
}
#textCenter {
  display: table-cell;
  text-align: center;
}
.popup-left {
  float: left;
  text-align: left;
  padding-left: 0.7vmin;
}

.header-left {
  float: left;
  width: 32%;
  text-align: left;
  padding-left: 0.7vmin;
}
.header-right {
  float: right;
  width: 32%;
  text-align: left;
}
.header-center {
  margin: 0px 0px auto !important;
  width: 32%;
  display: inline-flex;
  text-align: center;
}
.header-text-left {
  float: left;
  width: 10%;
  margin: 0px 5px;
  text-align: left;
}
.header-text-right {
  float: right;
  width: 35%;
  text-align: left;
}
.header-text-center {
  margin: 0px 0px auto !important;
  display: inline-flex;
  text-align: left;
  width: 8%;
}

/* .viewOrderbook
{
  //background-color: var(--windowColor);
  background-color: rgba(155,0,100,0.5);
  border: var(--windowBorder);

  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  position: absolute;
  top: 90px;
  right: var(--normalMargin);
  width: var(--rightSideWidth);
  min-width: 240px;
  overflow: hidden;
} */
.viewGraph {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  z-index: 12;
  position: absolute;
  top: var(--graphTop);
  right: calc(var(--rightSideMargin) + var(--normalMargin));
  height: var(--graphHeight);
  min-width: var(--graphMinWidth);
}
.viewDistributionMap {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  z-index: 12;
  position: absolute;
  top: var(--graphTop);
  left: calc(var(--leftSideWidth) + var(--normalMargin));
  right: calc(var(--rightSideMargin) + var(--normalMargin));
  height: calc(var(--graphHeight) + var(--auctionBuySellHeight));
  /*var(--graphHeight);*/
  min-width: var(--graphMinWidth);
}

.viewTransportBook {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  display: flex;
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  z-index: 5;
  position: absolute;
  right: var(--normalMargin);
  top: calc(1 * var(--normalMargin));
  height: calc(var(--auctionBuySellHeight) * 2);
  width: var(--rightSideWidth);
}

.viewYourTransportBook {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  display: flex;
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  z-index: 5;
  position: absolute;
  right: var(--normalMargin);
  top: calc(var(--normalMargin) + var(--auctionBuySellHeight) * 2);
  height: calc(var(--auctionBuySellHeight) * 2);
  width: var(--rightSideWidth);
}

.viewAuction {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  z-index: 12;
  position: absolute;
  left: calc(var(--leftSideWidth) + var(--normalMargin));
  top: var(--graphTop);
  right: calc(var(--rightSideMargin) + var(--normalMargin));
  height: var(--graphHeight);
  min-width: var(--graphMinWidth);
}

.viewAuctionTable {
  justify-content: center;
  display: inline-table;
  width: 90%;
}

.viewAuctionBook {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  display: flex;
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  z-index: 5;
  position: absolute;
  right: var(--normalMargin);
  //top: var(--graphTop); /*calc(2 * var(--cardHeight) + 2 * var(--normalMargin) + var(--topStart));*/
  top: calc(1 * var(--normalMargin));
  height: calc(
    var(--auctionBuySellHeight) + var(--buySellTop) + var(--auctionBuySellHeight) -
      15 * var(--normalMargin)
  );
  width: var(--rightSideWidth);
}
.viewAuctionCreateBid {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  position: absolute;
  top: var(--buySellTop);
  left: var(--leftSideMargin);
  right: calc(var(--rightSideMargin) + var(--normalMargin));
  height: calc(var(--auctionBuySellHeight));
  min-width: var(--graphMinWidth);
  display: flex;
}
.viewAuctionTextBottom {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  position: absolute;
  top: calc(var(--buySellTop) + var(--auctionBuySellHeight));
  left: var(--leftSideMargin);
  right: calc(var(--rightSideMargin) + var(--normalMargin));
  height: calc(var(--auctionBuySellHeight) - 15 * var(--normalMargin));
  min-width: calc(var(--auctionBuySellHeight) - 20 * var(--normalMargin));
  display: flex;
  text-align: left;
}

.viewOrdersBuySell {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  position: absolute;
  top: var(--buySellTop);
  left: var(--leftSideMargin);
  right: calc(var(--rightSideMargin) + var(--normalMargin));
  height: var(--buySellHeight);
  min-width: var(--graphMinWidth);
  display: flex;
  //animation-name: viewOrdersBuySellAnimation;
  //animation-duration: 2s;
}
@keyframes viewOrdersBuySellAnimation {
  from {
    height: 0;
  }
  to {
    height: var(--buySellHeight);
  }
}

.viewOrderBuy {
  background-color: var(--viewBuySellBackground);
  border: var(--viewBorder);
  color: var(--grayColor);
  width: 50%;
  overflow: hidden;
  display: flex;
  margin: 0px 5px;
}
.viewOrderSell {
  background-color: var(--viewBuySellBackground);
  border: var(--viewBorder);
  color: var(--grayColor);
  width: 50%;
  overflow: hidden;
  display: flex;
  margin: 0px 5px;
}

.viewOrderBuyInside {
  margin: 5px 0px;
  box-sizing: border-box;
  width: 100%;
}

.orderbuy-line {
  display: flex;
  flex-direction: row;
  white-space: pre-wrap;
  margin: 0px 0px;
}

.orderbuy-header {
  text-overflow: ellipsis;
  text-align: center;
  margin: 3px 1px;
}
.orderbuy-left {
  text-align: right;
  text-overflow: clip;
  overflow: hidden;
  margin: 5px 0px;
  width: 7ex;
  min-width: 5ex;
}
.orderbuy-right {
  display: flex;
  margin: 5px 0px;
  text-overflow: ellipsis;
}
.orderbuy-center {
  display: flex;
  width: 62%;
  margin: 0px 1px;
  text-overflow: ellipsis;
}

.orderbuy-button {
  margin: 1ex 1ex;
  text-align: center;
  justify-content: center;
  width: 80%;
  max-width: 200px;

  font-size: 1.5vmin;
  height: 3vmin;
}

.tradeinput {
  background-color: rgba(85, 85, 85, 0.3);
  border: 1px solid rgb(204, 204, 204);
  border-color: rgb(131, 131, 131);
  border-radius: 2px;

  color: white;
  text-align: right;
  justify-content: center;
  height: 24px;
  padding: 10px 10px;
  width: 100%;
}
.inputdisabled {
  background-color: rgba(85, 85, 85, 0.7);
  border: 0px solid rgb(204, 204, 204);
}
.orderSellView {
  background-color: var(--viewBackground);
  color: var(--grayColor);
  overflow: hidden;
  overflow-y: auto;
  vertical-align: bottom;
}
.orderBuyView {
  background-color: var(--viewBackground);
  color: var(--grayColor);
  overflow: hidden;
  overflow-y: auto;
  vertical-align: bottom;
}

.viewOrderbookSells {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: var(--topStart);
  right: var(--normalMargin);
  height: var(--cardHeight);
  width: var(--rightSideWidth);
  overflow: hidden;
  overflow-y: auto;
}
.viewOrderbookBuys {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(var(--cardHeight) + var(--normalMargin) + var(--topStart));
  right: var(--normalMargin);
  height: var(--cardHeight);
  width: var(--rightSideWidth);
  overflow: hidden;
  overflow-y: auto;
}
.viewTradebook {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  display: flex;
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  z-index: 5;
  position: absolute;
  right: var(--normalMargin);
  top: calc(2 * var(--cardHeight) + 2 * var(--normalMargin) + var(--topStart));
  height: var(--tradesBookHeight);
  width: var(--rightSideWidth);
}

.tradeView {
  background-color: var(--viewBackground);
  border: var(--viewBorder);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
}

.viewOpenTrades {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  top: var(--openTradesTop);
  left: var(--leftSideMargin);
  right: calc(var(--rightSideMargin) + var(--normalMargin));
  position: absolute;
  height: var(--openTradesHeight);
  overflow: hidden;
  overflow-y: auto;
  animation-name: viewOpenTradesAnimation;
  animation-duration: 1s;
}

@keyframes viewOpenTradesAnimation {
  from {
    height: 0;
  }
  to {
    height: var(--openTradesHeight);
  }
}

.openTradesText {
  text-overflow: ellipsis;
  min-width: 30px;
  overflow: hidden;
}

.openTradesView {
  background-color: var(--viewBackground);
  border: var(--viewBorder);
  /* color: var(--grayColor); */
  width: 100%;
}

.centerModalBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(30, 80, 172, 0.5);
}

.centerModalImageBackground {
  background: radial-gradient(
    circle,
    var(--toastInnerColor) 0%,
    var(--logoBackgroundColor) 10%
  );
  display: block;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-bottom: 5%;
  align-items: center;
  justify-content: center;
}
.centerModalImageBackground:focus {
  outline: 0px dashed cyan;
}

.centerModalImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  //box-shadow: 0 0 10.15em #1da9cc;
  box-shadow: 0 0 10.15em var(--centerModalImage);
}

@media only screen and (max-width: 812px) {
  .App {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 1023px) {
  .exchangesBanner {
    display: none;
  }
  .viewMarkets {
    display: none;
  }
  .viewMarketSummary {
    left: var(--normalMargin);
    right: var(--normalMargin);
    top: calc(1 * var(--normalMargin));
  }
  .viewGraph {
    left: var(--normalMargin);
    right: var(--normalMargin);
    height: calc(var(--graphHeightSmall));
    top: calc(var(--marketSummaryHeight) + 1 * var(--normalMargin));
  }
  .viewOrderbookSells {
    left: var(--normalMargin);
    width: 49.7vw;
    top: calc(
      var(--graphHeightSmall) + var(--marketSummaryHeight) + 2 * var(--normalMargin)
    );
    height: var(--buySellHeight);
  }
  .viewOrderbookBuys {
    right: var(--normalMargin);
    width: 49.7vw;
    top: calc(
      var(--graphHeightSmall) + var(--marketSummaryHeight) + 2 * var(--normalMargin)
    );
    height: var(--buySellHeight);
  }
  .viewOpenTrades {
    left: var(--normalMargin);
    width: 49.7vw;
    top: calc(
      var(--graphHeightSmall) + var(--marketSummaryHeight) + var(--buySellHeight) + 3 *
        var(--normalMargin)
    );
    height: var(--buySellHeight);
  }
  .viewTradebook {
    width: 49.7vw;
    top: calc(
      var(--graphHeightSmall) + var(--marketSummaryHeight) + var(--buySellHeight) + 3 *
        var(--normalMargin)
    );
    height: var(--buySellHeight);
  }
  .viewOrdersBuySell {
    left: var(--normalMargin);
    right: var(--normalMargin);
    top: calc(
      var(--graphHeightSmall) + var(--marketSummaryHeight) + 2 * var(--buySellHeight) +
        4 * var(--normalMargin)
    );
  }

  .footerBanner {
    left: var(--normalMargin);
  }
}

@media only screen and (min-width: 1024px) {
  .viewGraph {
    left: var(--leftSideMargin);
  }
}

button.MuiButton-text {
  color: var(--textColor);
}

td.MuiTableCell-body {
  color: var(--textColor);
}

p.MuiTypography-colorTextSecondary {
  color: var(--textColorCompleted);
}

span.MuiTypography-colorTextSecondary {
  color: var(--textColorCompleted);
}

span.MuiStepLabel-label.MuiStepLabel-completed {
  color: var(--textColorCompleted);
}

span.MuiStepLabel-label.MuiStepLabel-active {
  color: var(--textColorActive);
}

span.MuiStepLabel-label {
  color: var(--textColor);
}

div.MuiPaper-root {
  color: var(--textColor);
  background-color: var(--cardBackground);
  /* transparent; */
}

div.MuiPaper-elevation1 {
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
  box-shadow: 10px 2px 1px -1px rgb(10 0 0 / 10%), 0px 1px 0px 0px rgb(0 10 0 / 14%),
    0px 1px 0px 0px rgb(0 0 10 / 12%);
}
button.MuiButtonBase-root {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%);
}

div.MuiAccordionDetails-root {
  display: block;
}
div.MuiExpansionPanel-root.Mui-expanded {
  margin: 0px;
}
div.MuiExpansionPanelDetails-root {
  display: block;
}

div.MuiExpansionPanelSummary-content {
  display: block;
  background-color: rgba(0, 175, 155, 0.1);
}

svg.MuiSvgIcon-root {
  fill: var(--textColor);
}
h1.MuiCardHeader-root {
  padding: 10px;
  margin: 0px;
}

.MuiCardHeader-content {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.toast-error {
  background: radial-gradient(
    circle at 0px 0px,
    var(--toastErrorInnerColor) 0%,
    var(--toastErrorOuterColor) 100%
  );
  border-radius: 30px;
}

.toast-success {
  background: radial-gradient(
    circle at 0px 0px,
    var(--logoInnerColor) 50%,
    var(--logoBackgroundColor) 100%
  );
  border-radius: 30px;
}
.toast-info {
  background: radial-gradient(
    circle,
    var(--toastInnerColor) 50%,
    var(--toastOuterColor) 100%
  );
  border-radius: 30px;
}
.toast-info-popup {
  background: radial-gradient(
    circle,
    var(--toastInnerColor) 50%,
    var(--toastOuterColor) 100%
  );
  border-radius: 30px;
}

/* locationInnerColor */
.toast-info-popup-supply {
  background: radial-gradient(
    circle at bottom right,
    var(--supplyLocationColor) 0%,
    var(--toastInnerColor) 50%,
    var(--toastOuterColor) 100%
  );
  border-radius: 30px;
}

.toast-info-popup-demand {
  background: radial-gradient(
    circle at bottom right,
    var(--demandLocationColor) 0%,
    var(--toastInnerColor) 50%,
    var(--toastOuterColor) 100%
  );
  border-radius: 30px;
}

.rjsf {
  display: inline-block;
}
.rjsf .form-group {
  font-size: 1em;
  text-align: left;
  line-height: 1.8;
  width: 100%;
  margin: 5px;
}
.rjsf .form-control {
  width: 100%;
  text-align: left;
  font-size: 1.16em;
  background-color: rgba(200, 200, 200, 0.1);
  padding: 10px;
  border: none;
}

.viewSearchSummary {
  background-color: var(--windowColor);
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  position: absolute;
  height: var(--marketSummaryHeight);
  left: calc(var(--normalMargin));
  right: calc(0 * var(--rightSideMargin) + var(--normalMargin));
  overflow: hidden;
  min-width: var(--graphMinWidth);
  height: calc(var(--marketSummaryHeight) * 0.5);
  top: var(--topStart);
}

.viewSearchGroups {
  background-color: var(--windowColor);
  //border: none;
  border: var(--windowBorder);
  border-radius: var(--borderRadius);
  position: relative;
  text-align: left;
  justify-items: left;
  padding: 5px 0px;
  top: calc(0 * var(--topStart) + 0.2 * var(--marketSummaryHeight));
  left: calc(var(--normalMargin));
  width: calc(1.4 * var(--leftSideWidth));
  //height: var(--graphHeight);
  z-index: 7;
  overflow-y: auto;
}

.leftSide {
  width: 20%;
  float: left;
}

.rightSide {
  width: 80%;
  float: right;
}

.red {
  color: var(--redColor);
}

.topPadding {
  padding-top: 1.5rem;
  float: right;
}

.bodyCrack {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  overflow: hidden;
}

.header1 {
  position: relative;
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: calc(20px + 25vw);
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-shadow: 0 0 0.15em #1da9cc;
  user-select: none;
  white-space: nowrap;
  filter: blur(0.07em);
  animation: shake 2.5s linear forwards;
}

.header1 span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  -webkit-clip-path: polygon(10% 0%, 44% 0%, 70% 100%, 55% 100%);
  clip-path: polygon(10% 0%, 44% 0%, 70% 100%, 55% 100%);
}

.header1::before,
.header1::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
}

.header1::before {
  animation: crack1 2.5s linear forwards;
  -webkit-clip-path: polygon(0% 0%, 10% 0%, 55% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 10% 0%, 55% 100%, 0% 100%);
}

.header1::after {
  animation: crack2 2.5s linear forwards;
  -webkit-clip-path: polygon(44% 0%, 100% 0%, 100% 100%, 70% 100%);
  clip-path: polygon(44% 0%, 100% 0%, 100% 100%, 70% 100%);
}

@keyframes shake {
  5%,
  15%,
  25%,
  35%,
  55%,
  65%,
  75%,
  95% {
    filter: blur(0.038em);
    transform: translateY(0.018em) rotate(0deg);
  }

  10%,
  30%,
  40%,
  50%,
  70%,
  80%,
  90% {
    filter: blur(0.01em);
    transform: translateY(-0.018em) rotate(0deg);
  }

  20%,
  60% {
    filter: blur(0.03em);
    transform: translate(-0.018em, 0.018em) rotate(0deg);
  }

  45%,
  85% {
    filter: blur(0.03em);
    transform: translate(0.018em, -0.018em) rotate(0deg);
  }

  100% {
    filter: blur(0em);
    transform: translate(0) rotate(-0.5deg);
  }
}

@keyframes crack1 {
  0%,
  95% {
    transform: translate(-50%, -50%);
  }

  100% {
    transform: translate(-51%, -48%);
  }
}

@keyframes crack2 {
  0%,
  95% {
    transform: translate(-50%, -50%);
  }

  100% {
    transform: translate(-49%, -53%);
  }
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/Proxima Nova Alt Regular-webfont.eot');
  src: url('./fonts/Proxima Nova Alt Regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/Proxima Nova Alt Regular-webfont.woff') format('woff'),
    url('./fonts/Proxima Nova Alt Regular-webfont.ttf') format('truetype'),
    url('./fonts/Proxima Nova Alt Regular-webfont.svg#proxima_nova_altregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

/* abel-regular - latin */
@font-face {
  font-family: 'Abel';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/abel-v10-latin/abel-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Abel Regular'), local('Abel-Regular'),
    url('./fonts/abel-v10-latin/abel-v10-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/abel-v10-latin/abel-v10-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/abel-v10-latin/abel-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/abel-v10-latin/abel-v10-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/abel-v10-latin/abel-v10-latin-regular.svg#Abel') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
    url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.svg#Roboto')
      format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: 'Proxima Nova', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--backgroundColor);
  color: var(--textColor);
}

h1 {
  text-shadow: 0 0 0.7em #d71ddd;
  user-select: none;
  white-space: normal;
  //filter: blur(0.3px);
}
h2 {
  text-shadow: 0 0 0.7em #cc1dc3cc;
  user-select: none;
  white-space: normal;
  //filter: blur(0.2px);
}
h3 {
  text-shadow: 0 0 0.5em #cc1dc377;
  user-select: none;
  white-space: normal;
  //filter: blur(0.1px);
}
h4 {
  text-shadow: 0 0 0.5em #cc1dc377;
  user-select: none;
  white-space: normal;
  //filter: blur(0.1px);
}
h5 {
  text-shadow: 0 0 0.4em #cc1dc377;
  user-select: none;
  white-space: normal;
  //filter: blur(0.1px);
}

//font-family: -apple-system, BlinkMacSystemFont, "ProximaNovaAlt", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//sans-serif;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  background-color: rgb(121, 121, 121);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  color: white;
  text-align: center;
  text-decoration: none;
  box-shadow: 5px 5px 5px rgba(20, 20, 20, 0.3);
}

input {
  background-color: rgb(85, 85, 85);
  border: 1px solid rgb(204, 204, 204);
  border-color: rgb(131, 131, 131);
  border-radius: 5px;

  margin: 1px 2px;
  box-sizing: border-box;
  color: white;
  text-align: center;
  text-decoration: none;
  box-shadow: 5px 5px 5px rgba(20, 20, 20, 0.3);
  height: 1.5em;
}
.inputBox {
  height: 2.1em;
}

a {
  /* padding: 18px 7px;
  line-height: 24px;
  text-decoration: none;
  text-align: left;
  color: var(--linkColor);
  position: relative; */
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

/* unvisited link */
a:link {
  color: rgb(224, 189, 230);
  text-decoration: underline;
  text-shadow: 0 0 0.4em #e247f7cc;
  text-decoration-color: rgba(224, 289, 230, 0.4);
}

/* visited link */
a:visited {
  color: var(--textColor);
  text-shadow: 0 0 0.4em #ff00ea96;
}

/* mouse over link */
a:hover {
  color: var(--textHoverColor); /*rgb(255, 179, 148);*/
  text-shadow: 0 0 0.8em #f7ad26ff;
  //color: rgb(250, 200, 200);
  //text-shadow: 0 0 0.99em #d33ae0aa;
}
/*color: hotpink;*/

/* selected link */

a:active {
  color: rgb(215, 200, 200);
}

dt {
  font-weight: bold;
  margin: 0px 10px;
  padding: 0 0 0.5em 0em;
  text-align: left;
}
dd {
  margin: 0px 10px;
  padding: 0 0 0.2em 0em;
  text-align: left;
}
table {
  border: #d71ddd;
}
tr:nth-child(even) {
  background: rgba(70, 70, 70, 0.1);
  box-shadow: 0px 1px 5px 0px #ff00ea16;
}
thead {
  background: rgba(20, 20, 70, 0.3);
  text-shadow: 0 0 0.4em #ff00ea96;
  font-size: 1.2vmin;
}

table {
  margin: 0 0vmin 0 1vmin;
}

table,
th,
td {
  border-collapse: collapse;
  //border: 1px solid white;
}

th {
  background: var(--headerBackgroundColor);
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0px 5px 10px 0px #ff00ea16;
  padding: 2px 0px 2px 1px;
}
